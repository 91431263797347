<template>
  <div class="tabbar">
    <div class="item" @click="handleRedirect('Home')">
      <img class="icon" :src="current === 'Home' ? HomeActiveIcon : HomeIcon" />
      <div :class="`label ${current === 'Home' ? 'active' : ''}`">首页</div>
    </div>
    <div class="item" @click="showModal">
      <img class="icon add" src="../../../assets/images/tabbar/add.png" />
      <div class="label">功能</div>
    </div>
    <div class="item" @click="handleRedirect('MyIndex')">
      <img class="icon my" :src="current === 'MyIndex' ? MyActiveIcon : MyIcon" />
      <div :class="`label ${current === 'MyIndex' ? 'active' : ''}`">我的</div>
    </div>
    <div class="bg"></div>

    <div class="modal" v-if="isShowModal">
      <div class="content">
        <div class="nav">
          <router-link to="/agriculturalTechnology">
            <NavItem icon="njh" label="农技会" />
          </router-link>
          <router-link to="/demonstrationField">
            <NavItem icon="sft" label="示范田" />
          </router-link>
          <router-link to="/terminalOutlets">
            <NavItem icon="zdwd" label="终端网点" />
          </router-link>
          <router-link to="/bigGrowers">
            <NavItem icon="zzdh" label="种植大户" />
          </router-link>
          <router-link to="/potentialCustomers">
            <NavItem icon="qzkh" label="潜在客户" />
          </router-link>
        </div>
        <div class="button-list">
          <router-link :to="{name: 'OrderAddOrderFirst', params: {seller: 'self'}}">
            <div class="button order">下订单</div>
          </router-link>
          <router-link to="/task/publishTask">
            <div class="button task">发任务</div>
          </router-link>
        </div>
        <img class="close-icon" src="../../../assets/images/tabbar/close.png" @click="closeModal"/>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from '../../../assets/images/tabbar/home.png';
import HomeActiveIcon from '../../../assets/images/tabbar/home-active.png';
import MyIcon from '../../../assets/images/tabbar/my.png';
import MyActiveIcon from '../../../assets/images/tabbar/my-active.png';
import NavItem from '../../../views/home/components/navItem';
export default {
  components: {
    NavItem,
  },
  data() {
    return {
      HomeIcon,
      HomeActiveIcon,
      MyIcon,
      MyActiveIcon,
      isShowModal: false
    }
  },
  computed: {
    current() {
      return this.$route.name;
    }
  },
  methods: {
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    handleRedirect(target) {
      if (target !== this.current) {
        this.$router.push({ name: target });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  height: 65px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  padding:0 80px 4px;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 100;
  .icon {
    width: 23px;
    height: 22px;
    display: block;
    &.my {
      width: 20px;
      margin-left: 2px;
    }
    &.add {
      width: 49px;
      height: 49px;
      margin-bottom: -4px;
    }
  }
  .label {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #CECECE;
    margin-top: 1px;
    &.active {
      color: #CD001F;
    }
  }
  .bg {
    width: 100%;
    height: 49px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: flex-end;
    .content {
      height: 212px;
      width: 100%;
      background: #fff;
      border-radius: 10px 10px 0 0;
      .nav {
        display: flex;
        align-items: center;
        padding: 14px 24px 0;
        box-sizing: border-box;
        justify-content: space-between;
      }
      .button-list {
        padding: 13px 24px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .button {
          width: 157px;
          height: 50px;
          box-sizing: border-box;
          padding-left: 23px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #FFFFFF;
          background-color: #CD001F;
          box-shadow: rgba(235, 235, 235, 0.62);
          border-radius: 5px;
          &.order {
            background-image: url(../../../assets/images/tabbar/order.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          &.task {
            background-image: url(../../../assets/images/tabbar/task.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .close-icon {
        width: 12px;
        height: 12px;
        margin: 27px auto 0;
      }
    }
  }
}
</style>