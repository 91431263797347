<template>
  <div class="nav-item">
    <img class="icon" :src="iconSrc" />
    <div class="label">{{label}}</div>
  </div>
</template>

<script>
import ZdwdIcon from '../../../assets/images/home/zdwd.png';
import ZzdhIcon from '../../../assets/images/home/zzdh.png';
import QzkhIcon from '../../../assets/images/home/qzkh.png';
import KbqyIcon from '../../../assets/images/home/kbqy.png';
import NjhIcon from '../../../assets/images/home/njh.png';
import SftIcon from '../../../assets/images/home/sft.png';
import ZkglIcon from '../../../assets/images/home/zkgl.png';
import DxqfIcon from '../../../assets/images/home/dxqf.png';

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    }
  },
  computed: {
    iconSrc() {
      switch(this.icon) {
        case 'zdwd':
          return ZdwdIcon
        case 'zzdh': 
          return ZzdhIcon
        case 'qzkh': 
          return QzkhIcon
        case 'kbqy': 
          return KbqyIcon
        case 'njh': 
          return NjhIcon
        case 'sft': 
          return SftIcon
        case 'zkgl': 
          return ZkglIcon
        case 'dxqf': 
          return DxqfIcon
        default:
          return ZdwdIcon
      }
    }
  },
  data() {
    return {
    }
  }
  
}
</script>

<style lang="scss" scoped>
.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  .icon {
    width: 40px;
    height: 40px;
  }
  .label {
    margin-top: 6px;
    width: 40px;
    height: 14px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #818181;
  }
}
</style>